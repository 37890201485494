<template>
  <div class="all">
    <div class="intruduce">
      <div class="topOringin"> 
        <div class="left">
          <div class="oringinText">文 昌 市</div>
          <div class="oringinIntrduce">原产地介绍</div>
        </div>
        <div class="right">
          <img style="width: 100%;height: 100%;"  src="https://asset.fsytss.com/trace/wcj/icon_logo_twice_1.png" alt="">
        </div>
      </div>
      <div class="intruduceInfo">
       {{ sourceIntroduction }}
      </div>
      <div class="img">

<img style="height: 100%;width: 100%;" :src="sourceImage" alt="">
</div>
    </div>
    <!-- 视频 -->
    <div class="baseinfo" v-if="sourcePlayAddressUrl">
      <div class="placeAddress">
        <div class="mp3" @click="onClickPlay">
          <v-playback :url="data.url" v-if="data.url"></v-playback>
          <img
            alt=""
            src="https://asset.fsytss.com/trace/wcj/index_default.png"
            v-if="!data.play&&!sourceVideoCoverUrl"
          />
          <img
            alt=""
            :src="sourceVideoCoverUrl"
            v-if="!data.play&&sourceVideoCoverUrl"
          />
          <img
            alt=""
            src="../../assets/images/project1/play.png"
            class="start"
            v-if="!data.play"
          />
        </div>
      </div>
    </div>
    <div class="baseinfo2" v-if="!sourcePlayAddressUrl"></div>
    <!-- 支持 -->
      <!-- 底部技术支持 -->
      <!-- <div class="bottom">一天膳事提供技术服务</div> -->
  </div>
</template>
  
  <script>
import Vue from "vue";
import vPlayBack from "v-playback";
import { Image as VanImage } from "vant";
Vue.use(VanImage);
Vue.use(vPlayBack);

export default {
  name: "HomeProductTraceComponent",
  props: [
    "sourceImage",
    "sourceIntroduction",
    "sourceAddress",
    "sourcePlayAddressUrl",
    "latitude",
    "longitude",
    "sourceVideoCoverUrl"
  ],
  data() {
    return {
      data: {
        title: "原产地简介",

        map: null,

        url: "",
        autoPlay: false,
        play: false,
        // url: 'https://vide.fsytss.com/ImportVideo_799692.911942%20%281%29.mp4'
      },
    };
  },
  mounted() {
  },
  methods: {
    init() {},
    initMap() {
      let latitude = Number(this.latitude);
      let longitude = Number(this.longitude);

      // latitude = 37.86236
      // longitude = 113.58762

      const center = new TMap.LatLng(latitude, longitude); //设置地图中心点坐标
      this.map = new TMap.Map("container", {
        center: center,
        zoom: 12, //设置地图缩放级别
        baseMap: {
          type: "vector", //类型：失量底图
          // features: ["base", "building2d"]
        },
      });
      // // 手绘地图渲染,主要个性化手绘地图 参考文章2.个性化手绘地图
      // this.mapLayer();
      // // 图标和气泡窗的显示 参考文章3.地图标点信息+图标
      // this.multiMarker();
      // this.infoWindow();

      //创建并初始化MultiMarker
      var markerLayer = new TMap.MultiMarker({
        map: this.map, //指定地图容器
        //样式定义
        styles: {
          //创建一个styleId为"myStyle"的样式（styles的子属性名即为styleId）
          myStyle: new TMap.MarkerStyle({
            width: 25, // 点标记样式宽度（像素）
            height: 35, // 点标记样式高度（像素）
            // "src": '../assets/images/icon_no.png',  //图片路径
            //焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
            anchor: { x: 16, y: 32 },
          }),
        },
        //点标记数据数组
        geometries: [
          {
            id: "1", //点标记唯一标识，后续如果有删除、修改位置等操作，都需要此id
            styleId: "myStyle", //指定样式id
            position: new TMap.LatLng(latitude, longitude), //点标记坐标位置
            properties: {
              //自定义属性
              title: "marker1",
            },
          },
        ],
      });
    },

    onClickPlay() {
      this.data.play = true;
      this.data.url = this.sourcePlayAddressUrl;
    },
  },
};
</script>
  
  <style lang="less" scoped>
.all{
  background-image: linear-gradient(to  bottom, #c12720, #f68954);

}
.intruduce {
  position: relative;
  padding: 0 28px;
  // height: 255px;
  // background-color: #fff;
  margin: 0 auto;
  justify-content: center;
  z-index: 1;
}
.img{
  border-radius: 8px;
  overflow: hidden;
  img{
    border-radius: 16px;
  }
}
.intruduceInfo {
  padding: 12px 0;
  font-size: 15px;
  color: #fff;
  line-height: 27px;
  text-indent: 16px;
}
.baseinfo {
  margin: 0 -12px;
  margin-top: 26px;
  padding: 22px 0 20px 0;
  position: relative;
  background-image: linear-gradient(to bottom,
  #fef4cc 0%,       /* 开始颜色 */
        #ffffff 50%,      /* 在中间位置变为另一个颜色 */
        #fd985e 100%);    /* 结束颜色 */
  padding-bottom: 120px;
  margin-bottom: -100px;
}
.baseinfo2{
  margin: 0 -12px;
  margin-top: 26px;
  padding: 22px 0 20px 0;
  position: relative;
  background-image: linear-gradient(to bottom,
  #fef4cc 0%,       /* 开始颜色 */
        #ffffff 50%,      /* 在中间位置变为另一个颜色 */
        #fd985e 100%);    /* 结束颜色 */
  padding-bottom: 120px;
  margin-bottom: -100px;
}
.placeAddress {
  position: relative;
  background-color: #fff;
  width: 319px;
  margin: 0 auto;
  z-index: 1;
  background-image: linear-gradient(to bottom,#f5bf6c,  #bd442f);
  overflow: hidden;
  padding: 11px;
  box-sizing: border-box;
  border-radius: 8px;
}
.mp3 {
  flex: 1;
  img{
    height: 56vw; width: 100%; border-radius: 8px;display: block;
  }
  .start{
    position: absolute;top: 25vw;left:calc(50% - 7vw);width: 15vw;height: 15vw;
  }
}
.topOringin{
  // height: 200px;
  // border: 1px solid #000;
  padding-top: 16px;
  display: flex;
  justify-content: space-between;
}
.oringinText{
  font-weight: bold;
font-size: 31px;
color: #FFFFFF;
}
.oringinIntrduce{
  width: 92px;
  border-radius: 8px;
  border: 1px solid #fff;
  font-size: 14px;
color: #FFFFFF;
margin-top: 12px;
padding: 3px 0;
text-align: center;
letter-spacing: 2px;
}
.right{
  width: 98px;
  height: 47px;
}
</style>